<template>
    <div>
        <page-title :heading="$t('generic.lang_pageNotFound')" :subheading="$t('generic.lang_pageNotFound')" :icon=icon></page-title>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    export default {
        components: {
            PageTitle,
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>